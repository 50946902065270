import { colors, device, size } from "Theme";

const themeConfig = {
    primary: colors.palette.pictonBlue,
    palette:colors.palette,
    fontSizes:size,
    device:device
};

export default themeConfig
