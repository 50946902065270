import { createGlobalStyle } from "styled-components";
import { device, typography } from "./index";

// import DefaultFontWoff from '..//fonts/whitney/whitney-light-webfont.woff'
// import DefaultFontWoff2 from '..//fonts/whitney/whitney-light-webfont.woff2'

// import DefaultFontBoldWoff from '..//fonts/whitney/whitney-semibold-webfont.woff'
// import DefaultFontBoldWoff2 from '..//fonts/whitney/whitney-semibold-webfont.woff2'

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
    font-family: ${typography.primary};
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media ${device.tablet} {
        font-size: 16px;
    }
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }
  body {
    line-height: 1;
    font-size: 16px;
  }
  img {
    display: block;
  	width: 100%;
  	height: auto;
  }
  button:focus{
    outline:none
  }
  a { text-decoration:none; outline:none}
  a:focus{ outline:none}
  a:visited {text-decoration:none;}
  a:hover {text-decoration:none;}
  a:active {text-decoration:none; outline:none}
  * {-webkit-tap-highlight-color: transparent;}
  *:focus{
    outline:none
  }
  html{   scroll-behavior: smooth; }

  :target {
    /* scroll-margin-top: -1em; */
  }
  sup {
    font-size: 11px;
    position: relative;
    top: -5px;
  }
  /* background-colour-for-chrome-autocomplete 🤐 */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/quicksand/Quicksand-Regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/quicksand/Quicksand-Regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/quicksand/Quicksand-Regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/quicksand/Quicksand-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
        font-display: swap;
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/quicksand/Quicksand-Medium.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/quicksand/Quicksand-Medium.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/quicksand/Quicksand-Medium.woff') format('woff'), /* Modern Browsers */
        url('/fonts/quicksand/Quicksand-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
        font-display: swap;
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/quicksand/Quicksand-Bold.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/quicksand/Quicksand-Bold.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/quicksand/Quicksand-Bold.woff') format('woff'), /* Modern Browsers */
        url('/fonts/quicksand/Quicksand-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
        font-display: swap;
  }

`;
export default GlobalStyles;
