import React from "react";
import { ThemeProvider } from "styled-components";
import themeConfig from "Theme/themeConfig";
import GlobalStyles from "Theme/styleGlobal";
import Script from "next/script";
import "../src/Components/Molecules/CustomerCardFields/card.scss";

function App({ Component, pageProps }) {
    const GA_TRACKING_ID = process.env.GA_TRACKING_ID;

    return (
        <>
            <GlobalStyles />
            <Script
                strategy="afterInteractive"
                src="https://www.googleoptimize.com/optimize.js?id=GTM-PGSZR5V"
            />
            <Script id="gtm-script" strategy="afterInteractive">
                {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GA_TRACKING_ID}');
                `}
            </Script>
            <ThemeProvider theme={themeConfig}>
                <Component {...pageProps} />
            </ThemeProvider>
        </>
    );
}

export default App;
