import {isMobile} from "react-device-detect";

export const sizeDevice = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 960,
    laptopL: 1440,
    desktop: 1900,
    desktopL: 2560

};

export const device = {
    isMobile: isMobile,
    mobileS: `(min-width: ${sizeDevice.mobileS}px)`,
    mobileM: `(min-width: ${sizeDevice.mobileM}px)`,
    mobileL: `(min-width: ${sizeDevice.mobileL}px)`,
    tablet: `(min-width: ${sizeDevice.tablet}px)`,
    laptop: `(min-width: ${sizeDevice.laptop}px)`,
    laptopL: `(min-width: ${sizeDevice.laptopL}px)`,
    desktop: `(min-width: ${sizeDevice.desktop}px)`,
    desktopL: `(min-width: ${sizeDevice.desktopL}px)`
};
