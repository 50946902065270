export const palette = {
    black: "#1d1d1d",
    white: "#ffffff",
    pictonBlue:"#2EA3F2",
    lochmara:"#0383C3",
    mariner:"#286FB7",
    geyser:"#D2DAE5",
    orient:"#004D7E",
    bostonBlue:"#3A8AC1",
    plum:"#8c3787",
    yellowSea:"#F9AE00",
    royalHeath:"#9C267B",
    magenta: '#b41a83',
    summerSkyBlue: '#27aae1',
    darkBlue: '#005eb5',
}
