

/**
 * Just the font names.
 *
 * The various styles of fonts are defined in the <Text /> component.
 */
export const typography = {
    /**
     * The primary font.  Used in most places.
     */
    primary: "Quicksand, sans-serif",
    /**
     * An alternate font used for perhaps titles and stuff.
     */
}

// to convert https://nekocalc.com/px-to-rem-converter base 16px body


export const size = {
    smallest: '0.625rem', // 10
    small: '0.875rem', //14
    regular:'1rem', // 16
    big:  '1.25rem', // 20,
    biggest: '2.5rem', // 40,
    large: '3rem',
}

export const weight = {
    black: "900",
    boldest: "700",
    bold: "600",
    medium: "500",
    regular: "400",
    light: "300",
    thin: "100",
}
